import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFilterDateTimeComponent } from './table-filter-date-time.component';
import { MaterialModule } from '../../material-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MtxDatetimepickerModule,
  ],
  declarations: [TableFilterDateTimeComponent],
  exports: [TableFilterDateTimeComponent],
})

export class TableFilterDateTimeModule {
}
