import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCalendarHeaderComponent } from './header.component';
import { MaterialModule } from '../../material-components.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  declarations: [MatCalendarHeaderComponent],
  exports: [MatCalendarHeaderComponent]
})
export class MatCalendarHeaderModule {
}
