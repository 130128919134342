import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { City } from '../../interfaces/configuration/city';

@Injectable()

export class CityService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(municipality_id: number): Observable<City[] | boolean> {
    return new Observable<City[] | boolean>((o: Subscriber<City[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('instellingen.cities.index', {':municipality_id': municipality_id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<City[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(municipality_id: number, id: number): Observable<City | boolean> {
    return new Observable<City | boolean>((o: Subscriber<City | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('instellingen.cities.read', {':municipality_id': municipality_id, ':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<City>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(municipality_id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('instellingen.cities.create', {':municipality_id': municipality_id}), formData);
  }

  update(municipality_id: number, id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('instellingen.cities.update', {':municipality_id': municipality_id, ':id': id}), formData);
  }

  delete(municipality_id: number, id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('instellingen.cities.delete', {':municipality_id': municipality_id, ':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }
}
