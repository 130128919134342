import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ArchiveCollection } from '../../interfaces/archive/archive-collection';
import { User } from '../../interfaces/routering/user';
import { ArchiveReport } from '../../interfaces/archive/archive-report';

@Injectable()

export class ArchiveService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(): Observable<ArchiveCollection[] | boolean> {
    return new Observable<ArchiveCollection[] | boolean>((o: Subscriber<ArchiveCollection[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('archive.archivering.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ArchiveCollection[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<ArchiveCollection | boolean> {
    return new Observable<ArchiveCollection | boolean>((o: Subscriber<ArchiveCollection | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('archive.archivering.read', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ArchiveCollection>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('archive.archivering.create'), formData);
  }

  update(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('archive.archivering.update', {':id': id}), formData);
  }

  delete(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('archive.archivering.delete', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  users(): Observable<User[] | boolean> {
    return new Observable<User[] | boolean>((o: Subscriber<User[] | boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('archive.archivering.users'))
        .subscribe((response: ServerResponse): void => {
            if (typeof response.data !== 'undefined') {
              o.next(<User[]>response.data);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  invite(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('archive.archivering.invite', {':id': id}), formData);
  }

  resendInvitation(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('archive.archivering.resend-invitation', {':id': id}), formData);
  }

  reports(id: number): Observable<ArchiveReport[] | boolean> {
    return new Observable<ArchiveReport[] | boolean>((o: Subscriber<ArchiveReport[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('archive.archivering.reports', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (typeof response.data !== 'undefined') {
              o.next(<ArchiveReport[]>response.data);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  deleteReport(archive_collection_id: number, id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('archive.archivering.reports.delete', {
        ':archive_collection_id': archive_collection_id,
        ':id': id
      }), true, true)
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  approve(id: number): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('archive.archivering.approve', {':id': id}));
  }

  reject(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('archive.archivering.reject', {':id': id}), formData);
  }

  destroy(id: number): Observable<ServerResponse> {
    return this.api.delete(this.apiEndpoints.get('archive.archivering.destroy', {':id': id}), false);
  }

  export(id: number): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('archive.archivering.export', {':id': id}));
  }

  certificate(id: number): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('archive.archivering.certificate', {':id': id}));
  }

  logs(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('archive.archivering.logs', {':id': id}));
  }
}
