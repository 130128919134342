import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthenticationService } from './services/base/authentication.service';
import { ApiService } from './services/base/api.service';
import { SnackbarService } from './services/base/snackbar.service';
import { AddTokenInterceptor } from './interceptors/add-token.interceptor';
import { AddAcceptInterceptor } from './interceptors/add-accept.interceptor';
import { AuthorizationService } from './services/base/authorization.service';
import { FormService } from './services/base/form.service';
import { OrganisationService } from './services/routering/organisation.service';
import { ApiEndpointsService } from './services/base/api-endpoints.service';
import { DepartmentService } from './services/routering/department.service';
import { RoleService } from './services/routering/role.service';
import { UserService } from './services/routering/user.service';
import { FiltersService } from './services/base/filters.service';
import { HeadCategoryService } from './services/routering/head-category.service';
import { AddressBookService } from './services/configuration/address-book.service';
import { EmailTemplateService } from './services/configuration/email-template.service';
import { StandardReactionService } from './services/configuration/standard-reaction.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { KnowledgeBaseService } from './services/knowledge-base/knowledge-base.service';
import { QuillService } from './services/base/quill.service';
import { ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlDutch } from '../@fury/shared/internationalization/mat-paginator/mat-paginator-intl.dutch';
import { MunicipalityService } from './services/configuration/municipality.service';
import { CityService } from './services/configuration/city.service';
import { ReportProductService } from './services/configuration/report-product.service';
import { ReportSourceService } from './services/configuration/report-source.service';
import { ReportStatusService } from './services/configuration/report-status.service';
import { ReportStatusCodeService } from './services/configuration/report-status-code.service';
import { CompanyService } from './services/info/company.service';
import { ReportService } from './services/reports/report.service';
import { ReportHistoryService } from './services/configuration/report-history.service';
import { ReportPanelService } from './services/reports/report-panel.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatetimeAdapter, MTX_DATETIME_FORMATS, MtxNativeDatetimeModule } from '@ng-matero/extensions/core';
import { MomentDatetimeAdapter, MtxMomentDatetimeModule } from '@ng-matero/extensions-moment-adapter';
import { LightboxModule } from 'ng-gallery/lightbox';
import { RemovableAttachmentModule } from '../@fury/shared/removable-attachment/removable-attachment.module';
import { ReportPrintService } from './services/reports/report-print.service';
import { PublicKnowledgeBaseService } from './services/knowledge-base/public-knowledge-base.service';
import { FaqService } from './services/knowledge-base/faq.service';
import { AddSocketIdInterceptor } from './interceptors/add-socket-id.interceptor';
import { KnowledgeBaseArticlesService } from './services/knowledge-base/knowledge-base-articles.service';
import { SupportContactDetailsService } from './services/knowledge-base/support-contact-details.service';
import { MainCategoryService } from './services/routering/main-category.service';
import { SubCategoryService } from './services/routering/sub-category.service';
import { StatisticsService } from './services/statistics/statistics.service';
import { ReporterService } from './services/info/reporter.service';
import { LeafletMapService } from './services/base/leaflet-map.service';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { FuryAlertBoxModule } from '../@fury/shared/alert-box/alert-box.module';
import { LayoutService } from './layout/layout.service';
import { LocalStorageService } from './services/base/local-storage.service';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { DateTimeService } from './services/base/date-time.service';
import { MAT_SELECTSEARCH_DEFAULT_OPTIONS, MatSelectSearchOptions, NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ClientPortalGroupsService } from './services/client-portal-groups/client-portal-groups.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ClientPortalUserAgreementService } from './services/configuration/client-portal-user-agreement.service';
import { AnonymizedService } from './services/archive/anonymized.service';
import { ArchiveService } from './services/archive/archive.service';
import { SentEmailsService } from './services/sent-emails/sent-emails.service';
import { SentEmailEventsService } from './services/sent-emails/sent-email-events.service';
import { PendingChangesGuard } from './services/base/pending-changes-guard';
import { UnsavedChangesDialogComponent } from '../@fury/shared/dialog/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // Fury Core Modules
    AppRoutingModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,
    MtxNativeDatetimeModule,
    MtxMomentDatetimeModule,
    RemovableAttachmentModule,

    LightboxModule.withConfig({
      panelClass: 'fullscreen'
    }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true, // environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    QuillModule.forRoot({
      suppressGlobalRegisterWarning: true,
    }),
    FuryAlertBoxModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    MatButtonModule,

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [
    AppComponent,
    UnsavedChangesDialogComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
        {
          provide: DatetimeAdapter,
          useClass: MomentDatetimeAdapter,
        },
    {provide: DateAdapter, useClass: MomentDateAdapter},
    {
      provide: MTX_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD-MM-YY',
          monthInput: 'MMMM',
          yearInput: 'YYYY',
          timeInput: 'HH:mm',
          datetimeInput: 'DD-MM-YY HH:mm',
        },
        display: {
          dateInput: 'DD-MM-YY',
          monthInput: 'MMMM',
          yearInput: 'YYYY',
          timeInput: 'HH:mm',
          datetimeInput: 'DD-MM-YY HH:mm',
          monthYearLabel: 'YYYY MMMM',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
          popupHeaderDateLabel: 'MMM DD, ddd',
        },
      },
    },
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'DD-MM-YY'
        },
        display: {
          dateInput: 'DD-MM-YY',
          monthLabel: 'MMMM',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'MMMM',
          monthYearA11yLabel: 'YYYY'
        }
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        animationDuration: '0',
        disablePagination: true,
        preserveContent: true,
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAcceptInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddSocketIdInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useValue: MatPaginatorIntlDutch()
    },
    {
      provide: MAT_SELECTSEARCH_DEFAULT_OPTIONS,
      useValue: <MatSelectSearchOptions>{
        preventHomeEndKeyPropagation: true,
        clearSearchInput: false,
        noEntriesFoundLabel: 'Geen data',
        placeholderLabel: 'Zoeken ...'
      }
    },
    ApiEndpointsService,
    ApiService,
    AuthenticationService,
    AuthorizationService,
    FiltersService,
    QuillService,
    FormService,

    LeafletMapService,
    OrganisationService,
    DepartmentService,
    LocalStorageService,
    SnackbarService,
    RoleService,
    UserService,
    HeadCategoryService,
    MainCategoryService,
    SubCategoryService,
    AddressBookService,
    EmailTemplateService,
    StandardReactionService,
    FaqService,
    KnowledgeBaseService,
    KnowledgeBaseArticlesService,
    SupportContactDetailsService,
    PublicKnowledgeBaseService,
    MunicipalityService,
    CityService,
    ReportProductService,
    ReportSourceService,
    ReportStatusService,
    ReportStatusCodeService,
    ReportHistoryService,
    CompanyService,
    ReportService,
    ReportPanelService,
    ReportPrintService,
    StatisticsService,
    ReporterService,
    LayoutService,
    DateTimeService,
    ClientPortalGroupsService,
    ClientPortalUserAgreementService,
    AnonymizedService,
    ArchiveService,
    SentEmailsService,
    SentEmailEventsService,
    PendingChangesGuard,
  ]
})
export class AppModule {
}
