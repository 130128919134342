import { Component } from '@angular/core';

@Component({
  selector: 'fury-app-update-dialog',
  templateUrl: './app-update-dialog.component.html',
  styleUrls: ['./app-update-dialog.component.scss'],
})

export class AppUpdateDialogComponent {
  constructor() {
  }
}
