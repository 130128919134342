import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FuryCard,
  FuryCardActions,
  FuryCardContent,
  FuryCardHeader,
  FuryCardHeaderActions,
  FuryCardHeaderSubTitle,
  FuryCardHeaderTitle
} from './card.component';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

const cardComponents = [
  FuryCard,
  FuryCardHeader,
  FuryCardHeaderTitle,
  FuryCardHeaderSubTitle,
  FuryCardHeaderActions,
  FuryCardContent,
  FuryCardActions
];

@NgModule({
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [
    ...cardComponents
  ],
  exports: [
    ...cardComponents
  ]
})
export class FuryCardModule {
}
