import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { AddressBook } from '../../interfaces/configuration/address-book';

@Injectable()

export class AddressBookService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(): Observable<AddressBook[] | boolean> {
    return new Observable<AddressBook[] | boolean>((o: Subscriber<AddressBook[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('configuration.address-book.index')).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<AddressBook[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<AddressBook | boolean> {
    return new Observable<AddressBook | boolean>((o: Subscriber<AddressBook | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('configuration.address-book.read', {':id': id})).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<AddressBook>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('configuration.address-book.create'), formData);
  }

  update(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('configuration.address-book.update', {':id': id}), formData);
  }

  delete(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('configuration.address-book.delete', {':id': id})).subscribe(
        (response: ServerResponse): void => {
          if (response) {
            o.next(true);
          } else {
            o.next(false);
          }
        },
        (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('configuration.address-book.restore', {':id': id})).subscribe(
        (response: ServerResponse): void => {
          if (response) {
            o.next(true);
          } else {
            o.next(false);
          }
        },
        (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }
}
