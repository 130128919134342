import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Output() openSidenav: EventEmitter<any> = new EventEmitter();

  constructor() {
  }
}
