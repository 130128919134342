import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TableFiltersComponent } from './table-filters.component';
import { TableFilterStringModule } from '../table-filter-string/table-filter-string.module';
import { TableFilterSelectModule } from '../table-filter-select/table-filter-select.module';
import { TableFilterDateRangeModule } from '../table-filter-date-range/table-filter-date-range.module';
import { TableFilterReportNumberModule } from '../table-filter-report-number/table-filter-report-number.module';
import { TableFilterDateModule } from '../table-filter-date/table-filter-date.module';
import { TableFilterDateTimeModule } from '../table-filter-date-time/table-filter-date-time.module';
import { TableFilterAutocompleteModule } from '../table-filter-autocomplete/table-filter-autocomplete.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    TableFilterStringModule,
    TableFilterSelectModule,
    TableFilterDateTimeModule,
    TableFilterDateRangeModule,
    TableFilterReportNumberModule,
    TableFilterDateModule,
    TableFilterAutocompleteModule,
  ],
  declarations: [TableFiltersComponent],
  exports: [TableFiltersComponent],
})

export class TableFiltersModule {
}
