import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { Company } from '../../interfaces/info/company';

@Injectable()

export class CompanyService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  fixCompanyOptions(): Observable<Company[] | boolean> {
    return new Observable<Company[] | boolean>((o: Subscriber<Company[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('info.companies.fix-company-options.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Company[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  fixCompanyOption(id: number, option_id: number): Observable<Company | boolean> {
    return new Observable<Company | boolean>((o: Subscriber<Company | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('info.companies.fix-company-options.update', {
        ':id': id,
        ':option_id': option_id
      }))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Company>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  all(): Observable<Company[] | boolean> {
    return new Observable<Company[] | boolean>((o: Subscriber<Company[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('info.companies.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Company[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<Company | boolean> {
    return new Observable<Company | boolean>((o: Subscriber<Company | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('info.companies.read', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Company>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('info.companies.create'), formData);
  }

  update(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('info.companies.update', {':id': id}), formData);
  }

  updateName(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('info.companies.update-name', {':id': id}), formData);
  }

  merge(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('info.companies.merge'), formData);
  }

  delete(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('info.companies.delete', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('info.companies.restore', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  import(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('info.companies.import'), formData);
  }

  export(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('info.companies.export'));
  }

  search(): Observable<ServerResponse> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.companies.search'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response !== 'undefined') {
            o.next(<ServerResponse>response);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  seed(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('info.companies.seed'), formData);
  }

  history(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('info.companies.history', {':id': id}));
  }
}
