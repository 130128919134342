import { Component } from '@angular/core';
import { AuthenticationService } from '../../../services/base/authentication.service';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent {

  public isOpen: boolean;
  public isLoadingImpersonation: boolean = false;
  public isLoadingLogout: boolean = false;

  constructor(public authenticationService: AuthenticationService,
  ) {
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  onClickOutside(): void {
    this.isOpen = false;
  }

  captureBug(): void {
    this.isOpen = false;
    window.Marker.capture();
  }

  stopImpersonating(): void {
    if (!this.isLoadingImpersonation) {
      this.isLoadingImpersonation = true;
      this.authenticationService.stopImpersonating().then((response: boolean): void => {
        if (response) {
          this.isLoadingImpersonation = false;
          this.isOpen = false;
        }
      });
    }
  }

  logout(): void {
    if (!this.isLoadingLogout) {
      this.isLoadingLogout = true;
      this.authenticationService.logout();
    }
  }
}
