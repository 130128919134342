import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { SubCategory } from '../../interfaces/routering/sub-category';

@Injectable()

export class SubCategoryService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(head_category_id: number, main_category_id: number): Observable<SubCategory[] | boolean> {
    return new Observable<SubCategory[] | boolean>((o: Subscriber<SubCategory[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('routering.sub-categories.index', {
        ':head_category_id': head_category_id,
        ':main_category_id': main_category_id
      })).subscribe((response: ServerResponse): void => {
        if (typeof response.data !== 'undefined') {
          o.next(<SubCategory[]>response.data);
        } else {
          o.next(false);
        }
      }, (): void => {
        o.next(false);
      });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(head_category_id: number, main_category_id: number, id: number): Observable<SubCategory | boolean> {
    return new Observable<SubCategory | boolean>((o: Subscriber<SubCategory | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('routering.sub-categories.read', {
        ':head_category_id': head_category_id,
        ':main_category_id': main_category_id,
        ':id': id
      })).subscribe((response: ServerResponse): void => {
        if (typeof response.data !== 'undefined') {
          o.next(<SubCategory>response.data);
        } else {
          o.next(false);
        }
      }, (): void => {
        o.next(false);
      });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(head_category_id: number, main_category_id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('routering.sub-categories.create', {
      ':head_category_id': head_category_id,
      ':main_category_id': main_category_id
    }), formData);
  }

  update(head_category_id: number, main_category_id: number, id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('routering.sub-categories.update', {
      ':head_category_id': head_category_id,
      ':main_category_id': main_category_id,
      ':id': id
    }), formData);
  }

  delete(head_category_id: number, main_category_id: number, id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('routering.sub-categories.delete', {
        ':head_category_id': head_category_id,
        ':main_category_id': main_category_id,
        ':id': id
      }))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(head_category_id: number, main_category_id: number, id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('routering.sub-categories.restore', {
        ':head_category_id': head_category_id,
        ':main_category_id': main_category_id,
        ':id': id
      }))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }
}
