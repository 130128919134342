import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public scrollbar: InfiniteScrollDirective;
  public scrollbarRef: ElementRef;

  public appUpdatePending: boolean = false;
  public updateApp: Subject<void> = new Subject<void>();

  public onScrollDown: EventEmitter<any> = new EventEmitter<any>();
  public resetInfiniteScroll: EventEmitter<any> = new EventEmitter<any>();

  public infiniteScrollDisabled: boolean = false;

  private _isLoading: boolean = false;

  constructor() {
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    setTimeout((): void => {
      this._isLoading = value;
    }, 0);
  }
}
