import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'fury-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})

export class DeleteDialogComponent {

  public id: string = '';
  public text: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }
}
