import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BackdropModule } from '../../@fury/shared/backdrop/backdrop.module';
import { MaterialModule } from '../../@fury/shared/material-components.module';
import { LayoutComponent } from './layout.component';
import { QuickpanelModule } from './quickpanel/quickpanel.module';
import { SidenavModule } from './sidenav/sidenav.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { FurySharedModule } from '../../@fury/fury-shared.module';
import { NavigationModule } from './navigation/navigation.module';
import { EditorModule } from '../pages/reports/editor/editor.module';
import { ReportPrintModule } from './report-print/report-print.module';
import { LoadingOverlayModule } from '../../@fury/shared/loading-overlay/loading-overlay.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FurySharedModule,

    // Core
    ToolbarModule,
    QuickpanelModule,
    SidenavModule,
    BackdropModule,
    NavigationModule,
    EditorModule,
    ReportPrintModule,
    LoadingOverlayModule,
    InfiniteScrollModule
  ],
  declarations: [LayoutComponent]
})
export class LayoutModule {
}
