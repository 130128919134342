import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFilterReportNumberComponent } from './table-filter-report-number.component';
import { MaterialModule } from '../../material-components.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  declarations: [TableFilterReportNumberComponent],
  exports: [TableFilterReportNumberComponent],
})

export class TableFilterReportNumberModule {
}
