import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material-components.module';
import { PageHeaderButtonsComponent } from './page-header-buttons.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
  ],
  declarations: [PageHeaderButtonsComponent],
  exports: [PageHeaderButtonsComponent]
})
export class PageHeaderButtonsModule {
}
