import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()

export class LocalStorageService {

  set(key: string, data: Object): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  get(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }

  getFilters(page: string) {
    const pageData = JSON.parse(localStorage.getItem(page));

    return pageData ?? null;
  }

  preserveFilter(page: string, key: string, value: any): void {
    if (page && key) {
      let pageData = JSON.parse(localStorage.getItem(page));

      if (pageData) {
        pageData[key] = value;
      } else {
        pageData = {
          [key]: value
        };
      }

      localStorage.setItem(page, JSON.stringify(pageData));
    }
  }

  removeFilter(page: string, key: string): void {
    if (page && key) {
      const pageData = JSON.parse(localStorage.getItem(page));

      if (pageData) {
        delete pageData[key];
      }

      if (!_.isEmpty(pageData)) {
        localStorage.setItem(page, JSON.stringify(pageData));
      } else {
        localStorage.removeItem(page);
      }
    }
  }

  clearFilters(page: string): void {
    if (page) {
      localStorage.removeItem(page);
    }
  }
}
