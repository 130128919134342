import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFilterDateRangeComponent } from './table-filter-date-range.component';
import { MaterialModule } from '../../material-components.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  declarations: [TableFilterDateRangeComponent],
  exports: [TableFilterDateRangeComponent],
})

export class TableFilterDateRangeModule {
}
