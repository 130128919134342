import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFilterStringComponent } from './table-filter-string.component';
import { MaterialModule } from '../../material-components.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  declarations: [TableFilterStringComponent],
  exports: [TableFilterStringComponent],
})

export class TableFilterStringModule {
}
