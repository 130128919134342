import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'fury-delete-dialog',
  templateUrl: './delete-with-note-dialog.component.html',
  styleUrls: ['./delete-with-note-dialog.component.scss'],
})

export class DeleteWithNoteDialogComponent {

  public isLoading: boolean = false;

  public id: string = '';
  public text: string = '';

  public form = this.fb.group({
    note: new FormControl(null, {validators: Validators.required})
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder) {
  }
}
