import { Component } from '@angular/core';

@Component({
  selector: 'fury-app-update-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss'],
})

export class UnsavedChangesDialogComponent {
  constructor() {
  }
}
