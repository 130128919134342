import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';
import { AuthenticationService } from './services/base/authentication.service';
import { User } from './interfaces/routering/user';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppUpdateDialogComponent } from '../@fury/shared/dialog/app-update-dialog/app-update-dialog.component';
import { SnackbarService } from './services/base/snackbar.service';
import { interval, Observable } from 'rxjs';
import Echo from 'laravel-echo';
import { environment } from '../environments/environment';
import { LocalStorageService } from './services/base/local-storage.service';
import { version } from '../environments/version';
import { LayoutService } from './layout/layout.service';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  private swUpdatePending: boolean = false;

  constructor(private sidenavService: SidenavService,
              private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
              private platform: Platform,
              private splashScreenService: SplashScreenService, // required!! otherwise splashScreen does not go away on app load
              private authenticationService: AuthenticationService,
              private swUpdate: SwUpdate,
              private dialogService: MatDialog,
              private snackbarService: SnackbarService,
              private localStorageService: LocalStorageService,
              private layoutService: LayoutService,
  ) {
    this.renderer.addClass(this.document.body, 'fury-default');
    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    if (this.swUpdate.isEnabled) {
      const oncePerHour$: Observable<number> = interval(360 * 1000);

      oncePerHour$.subscribe((): void => {
        console.log('onceAppIsStable$');
        this.swUpdate.checkForUpdate()
          .then((): void => console.log('checkForUpdate()'))
          .catch((error): void => {
            console.log('Service worker not supported or disabled!');
            throw new Error(error);
          });
      });

      this.swUpdate.versionUpdates.subscribe((event: VersionEvent): void => {
        if (!this.swUpdatePending) {
          if (event.type === 'VERSION_DETECTED') {
            this.snackbarService.info('Er is een nieuwe versie van de applicatie beschikbaar die op de achtergrond wordt klaargezet.');
          }
          if (event.type === 'VERSION_READY') {
            console.log('ServiceWorker update available: yes');
            this.swUpdatePending = true;
            let dialogRef: MatDialogRef<AppUpdateDialogComponent>;
            dialogRef = this.dialogService.open(AppUpdateDialogComponent, {
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(response => {
              if (response === true) {
                this.swUpdate.activateUpdate().then((): void => {
                  window.location.reload();
                });
              } else {
                this.layoutService.appUpdatePending = true;
                this.layoutService.updateApp.subscribe((): void => {
                  this.swUpdate.activateUpdate().then((): void => {
                    window.location.reload();
                  });
                });
              }
            });
          }
        }
      });
    }

    this.authenticationService.user$.subscribe((user: User): void => {
      if (user) {
        window.Echo = new Echo({
          ...environment.pusher,
          ...{
            authEndpoint: environment.api_endpoint + 'broadcasting/auth',
            auth: {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.localStorageService.get('api-token')}`
              }
            }
          }
        });

        if (typeof window.Marker !== 'undefined') {
          window.markerConfig.reporter = {
            email: user.email,
            fullName: user.name,
          };

          window.Marker.setCustomData({
            version: version
          });
        }

        this.sidenavService.items = [];
        this.sidenavService.addItems([
          {
            name: 'Meldingen',
            icon: 'assignment',
            position: 10,
            alwaysOpened: true,
            permissions: [
              'can-assign-new-reports',
              'can-bulk-handle-reports',
              'can-copy-reports',
              'can-create-reports',
              'can-delete-reports',
              'can-edit-closed-reports',
              'can-forward-reports',
              'can-print-reports',
              'can-read-reports-from-all-organisations',
              'can-reject-reports',
              'can-reopen-closed-reports',
              'can-resolve-reports',
              'can-see-deleted-reports',
              'can-see-unassigned-reports',
              'can-update-reports',
              'can-view-reports',
            ],
            subItems: [
              {
                name: 'Openstaand',
                routeOrFunction: '/meldingen',
                icon: 'assignment',
                position: 10,
                badgeItem: 'reports_open',
                permissions: [
                  'can-assign-new-reports',
                  'can-bulk-handle-reports',
                  'can-copy-reports',
                  'can-create-reports',
                  'can-delete-reports',
                  'can-edit-closed-reports',
                  'can-forward-reports',
                  'can-print-reports',
                  'can-read-reports-from-all-organisations',
                  'can-reject-reports',
                  'can-reopen-closed-reports',
                  'can-resolve-reports',
                  'can-see-deleted-reports',
                  'can-see-unassigned-reports',
                  'can-update-reports',
                  'can-view-reports',
                ],
              },
              {
                name: 'Gesloten',
                routeOrFunction: '/meldingen/gesloten',
                icon: 'assignment_turned_in',
                position: 15,
                badgeItem: 'reports_closed',
                permissions: [
                  'can-assign-new-reports',
                  'can-bulk-handle-reports',
                  'can-copy-reports',
                  'can-create-reports',
                  'can-delete-reports',
                  'can-edit-closed-reports',
                  'can-forward-reports',
                  'can-print-reports',
                  'can-read-reports-from-all-organisations',
                  'can-reject-reports',
                  'can-reopen-closed-reports',
                  'can-resolve-reports',
                  'can-see-deleted-reports',
                  'can-see-unassigned-reports',
                  'can-update-reports',
                  'can-view-reports',
                ],
              },
              {
                name: 'Alle',
                routeOrFunction: '/meldingen/alle',
                icon: 'assignment',
                position: 20,
                permissions: [
                  'can-assign-new-reports',
                  'can-bulk-handle-reports',
                  'can-copy-reports',
                  'can-create-reports',
                  'can-delete-reports',
                  'can-edit-closed-reports',
                  'can-forward-reports',
                  'can-print-reports',
                  'can-read-reports-from-all-organisations',
                  'can-reject-reports',
                  'can-reopen-closed-reports',
                  'can-resolve-reports',
                  'can-see-deleted-reports',
                  'can-see-unassigned-reports',
                  'can-update-reports',
                  'can-view-reports',
                ],
              },
            ]
          },
          {
            name: 'Nieuwe melding',
            routeOrFunction: '/meldingen/nieuw',
            icon: 'add_user',
            position: 15,
            alwaysOpened: false,
            permissions: [
              'can-create-reports'
            ],
          },
          {
            name: 'Selectie',
            routeOrFunction: '/selectie',
            icon: 'search',
            position: 20,
            alwaysOpened: false,
            permissions: [
              'can-view-report-search-page'
            ],
          },
          {
            name: 'Bedrijven',
            routeOrFunction: '/info/bedrijven',
            icon: 'business',
            position: 95,
            alwaysOpened: false,
            permissions: [
              'companies.create',
              'companies.read',
              'companies.update',
              'companies.delete',
            ],
          },
          {
            name: 'Kaart weergave',
            routeOrFunction: '/kaart-weergave',
            icon: 'map',
            position: 96,
            alwaysOpened: false,
            permissions: [
              'can-view-map-page',
            ],
          },
          {
            name: 'Rapportages',
            routeOrFunction: '/statistics',
            icon: 'insert_chart',
            position: 97,
            alwaysOpened: false,
            permissions: [
              'can-read-statistics'
            ],
          },
          {
            name: 'Melders',
            routeOrFunction: '/info/melders',
            icon: 'account_circle',
            position: 98,
            alwaysOpened: false,
            permissions: [
              'config-can-manage-reporters',
            ]
          },
          {
            name: 'BEHEER',
            position: 100,
            type: 'subheading',
            permissions: [
              'config-can-manage-organisations',
              'config-can-manage-departments',
              'config-can-manage-all-departments',
              'config-can-manage-roles',
              'config-can-manage-users',
              'config-can-manage-users-from-all-organisations',
              'config-can-manage-categories',
              'config-can-manage-organisations',
              'config-can-manage-departments',
              'config-can-manage-roles',
              'config-can-manage-users',
              'config-can-manage-users-from-all-organisations',
              'config-can-manage-categories',
              'config-can-manage-public-notifications',
              'can-manage-client-portal-users',
              'config-can-manage-email-templates',
              'config-can-manage-standard-reactions',
              'config-can-manage-address-book',
              'config-can-manage-knowledge-base',
              'config-can-manage-faq',
              'config-can-manage-changelogs',
              'config-can-manage-municipalities',
              'config-can-manage-report-products',
              'config-can-manage-report-statuses',
              'config-can-manage-report-sources',
              'config-can-manage-report-status-codes',
              'config-can-manage-report-history',
              'config-can-manage-report-causer-types',
              'config-can-manage-reporter-types',
              'config-can-manage-reporter-contact-timeframes',
              'config-can-manage-company-options',
              'config-can-manage-unusual-incident-causes',
              'config-can-manage-unusual-incident-consequences',
              'can-import-companies',
              'config-can-import-reporters',
              'can-export-reports-into-csv',
              'can-manage-archive',
              'can-create-api-token',
              'can-manage-client-portal-user-agreement',
            ],
          },
          {
            name: 'Routering',
            icon: 'call_split',
            position: 100,
            alwaysOpened: false,
            permissions: [
              'config-can-manage-organisations',
              'config-can-manage-departments',
              'config-can-manage-all-departments',
              'config-can-manage-roles',
              'config-can-manage-users',
              'config-can-manage-users-from-all-organisations',
              'config-can-manage-categories',
              'can-create-api-token',
            ],
            subItems: [
              {
                name: 'Organisaties',
                routeOrFunction: '/routering/organisaties',
                position: 10,
                permissions: [
                  'config-can-manage-organisations'
                ],
              },
              {
                name: 'API Token',
                routeOrFunction: '/routering/organisatie-api-token',
                position: 11,
                permissions: [
                  'can-create-api-token',
                ],
              },
              {
                name: 'Afdelingen',
                routeOrFunction: '/routering/afdelingen',
                position: 15,
                permissions: [
                  'config-can-manage-departments',
                  'config-can-manage-all-departments',
                ],
              },
              {
                name: 'Gebruikertypes',
                routeOrFunction: '/routering/gebruikertypes',
                position: 20,
                permissions: [
                  'config-can-manage-roles',
                ],
              },
              {
                name: 'Gebruikers',
                routeOrFunction: '/routering/gebruikers',
                position: 25,
                permissions: [
                  'config-can-manage-users',
                  'config-can-manage-users-from-all-organisations',
                ],
              },
              {
                name: 'Categorieën',
                routeOrFunction: '/routering/categorieen',
                position: 30,
                permissions: [
                  'config-can-manage-categories'
                ],
              },
            ]
          },
          {
            name: 'Publieke notificaties',
            icon: 'add_alert',
            position: 140,
            routeOrFunction: '/aankondigingen',
            permissions: [
              'config-can-manage-public-notifications',
            ]
          },
          {
            name: 'Klantenportaal',
            routeOrFunction: '/klantenportaal',
            icon: 'pages',
            position: 145,
            alwaysOpened: false,
            permissions: [
              'can-manage-client-portal-users',
            ]
          },
          {
            name: 'Instellingen',
            icon: 'settings',
            position: 150,
            alwaysOpened: false,
            permissions: [
              'config-can-manage-email-templates',
              'config-can-manage-standard-reactions',
              'config-can-manage-address-book',
              'config-can-manage-knowledge-base',
              'config-can-manage-faq',
              'config-can-manage-changelogs',
              'config-can-manage-municipalities',
              'config-can-manage-report-products',
              'config-can-manage-report-statuses',
              'config-can-manage-report-sources',
              'config-can-manage-report-status-codes',
              'config-can-manage-report-history',
              'config-can-manage-report-causer-types',
              'config-can-manage-reporter-types',
              'config-can-manage-reporter-contact-timeframes',
              'config-can-manage-company-options',
              'config-can-manage-unusual-incident-causes',
              'config-can-manage-unusual-incident-consequences',
              'can-manage-client-portal-user-agreement',
            ],
            subItems: [
              {
                name: 'Werkgebied',
                routeOrFunction: '/instellingen/werkgebied',
                position: 0,
                permissions: [
                  'config-can-manage-municipalities'
                ],
              },
              {
                name: 'E-mail templates',
                routeOrFunction: '/instellingen/email-templates',
                position: 10,
                permissions: [
                  'config-can-manage-email-templates'
                ],
              },
              {
                name: 'Standaard reacties',
                routeOrFunction: '/instellingen/standaard-reacties',
                position: 15,
                permissions: [
                  'config-can-manage-standard-reactions'
                ],
              },
              {
                name: 'Adressenboek',
                routeOrFunction: '/instellingen/adressenboek',
                position: 20,
                permissions: [
                  'config-can-manage-address-book'
                ],
              },
              {
                name: 'Handleiding',
                position: 25,
                alwaysOpened: false,
                permissions: [
                  'config-can-manage-knowledge-base',
                  'config-can-manage-faq',
                  'config-can-manage-changelogs',
                ],
                subItems: [
                  {
                    name: 'Handleiding',
                    routeOrFunction: '/instellingen/handleiding',
                    position: 10,
                    permissions: [
                      'config-can-manage-knowledge-base',
                    ]
                  },
                  {
                    name: 'FAQ',
                    routeOrFunction: '/instellingen/handleiding/faq',
                    position: 15,
                    permissions: [
                      'config-can-manage-faq',
                    ]
                  },
                  {
                    name: 'Organisaties',
                    routeOrFunction: '/instellingen/handleiding/organisaties',
                    position: 16,
                    permissions: []
                  },
                  {
                    name: 'Contact gegevens',
                    routeOrFunction: '/instellingen/handleiding/contact-gegevens',
                    position: 20,
                    permissions: []
                  },
                  {
                    name: 'Update applicatie',
                    position: 25,
                    routeOrFunction: '/instellingen/handleiding/update-applicatie',
                    permissions: [
                      'config-can-manage-changelogs',
                    ],
                  },
                ],
              },
              {
                name: 'Invoer',
                alwaysOpened: false,
                position: 30,
                subItems: [
                  {
                    name: 'Producten',
                    routeOrFunction: '/instellingen/invoer/producten',
                    position: 10,
                    permissions: [
                      'config-can-manage-report-products'
                    ],
                  },
                  {
                    name: 'Statussen',
                    routeOrFunction: '/instellingen/invoer/statussen',
                    position: 15,
                    permissions: [
                      'config-can-manage-report-statuses'
                    ],
                  },
                  {
                    name: 'Wijzen van melden',
                    routeOrFunction: '/instellingen/invoer/wijzen-van-melden',
                    position: 20,
                    permissions: [
                      'config-can-manage-report-sources'
                    ],
                  },
                  {
                    name: 'Geschiedenis',
                    routeOrFunction: '/instellingen/invoer/geschiedenis',
                    position: 25,
                    permissions: [
                      'config-can-manage-report-history'
                    ],
                  },
                  {
                    name: 'Resultaat afhandeling',
                    routeOrFunction: '/instellingen/invoer/resultaat-afhandeling',
                    position: 30,
                    permissions: [
                      'config-can-manage-report-status-codes'
                    ],
                  },
                  {
                    name: 'Type veroorzaker',
                    routeOrFunction: '/instellingen/invoer/type-veroorzaker',
                    position: 35,
                    permissions: [
                      'config-can-manage-causer-types'
                    ],
                  },
                  {
                    name: 'Melder type',
                    routeOrFunction: '/instellingen/invoer/melder-type',
                    position: 40,
                    permissions: [
                      'config-can-manage-reporter-types'
                    ],
                  },
                  {
                    name: 'Melder contact plaatsgevonden',
                    routeOrFunction: '/instellingen/invoer/melder-contact-plaatsgevonden',
                    position: 40,
                    permissions: [
                      'config-can-manage-reporter-contact-timeframes'
                    ],
                  },
                  {
                    name: 'Bedrijfseigenschappen',
                    routeOrFunction: '/instellingen/invoer/bedrijfseigenschappen',
                    position: 50,
                    permissions: [
                      'config-can-manage-company-options'
                    ],
                  },
                  {
                    name: 'Ongewoon voorval',
                    alwaysOpened: false,
                    position: 60,
                    permissions: [
                      'config-can-manage-unusual-incident-causes',
                      'config-can-manage-unusual-incident-consequences'
                    ],
                    subItems: [
                      {
                        name: 'Oorzaak',
                        routeOrFunction: '/instellingen/invoer/ongewoon-voorval/oorzaak',
                        position: 0,
                        permissions: [
                          'config-can-manage-unusual-incident-causes'
                        ]
                      },
                      {
                        name: 'Verwachte gevolgen',
                        routeOrFunction: '/instellingen/invoer/ongewoon-voorval/verwachte-gevolgen',
                        position: 10,
                        permissions: [
                          'config-can-manage-unusual-incident-consequences'
                        ]
                      }
                    ],
                  },
                ]
              },
              {
                name: 'Publieke notificaties types',
                routeOrFunction: '/aankondigingen/types',
                position: 35,
                permissions: [
                  'config-can-manage-public-notifications'
                ],
              },
              {
                name: 'Update applicaties types',
                routeOrFunction: '/instellingen/handleiding/update-applicatie/types',
                position: 40,
                permissions: [
                  'config-can-manage-changelog-types',
                ],
              },
              {
                name: 'Gebruikersovereenkomst',
                routeOrFunction: '/instellingen/gebruikersovereenkomst',
                position: 50,
                permissions: [
                  'can-manage-client-portal-user-agreement',
                ]
              },
            ]
          },
          {
            name: 'Import / export',
            icon: 'import_export',
            position: 200,
            alwaysOpened: false,
            permissions: [
              'can-import-companies',
              'config-can-import-reporters',
              'can-export-reports-into-csv',
            ],
            subItems: [
              {
                name: 'Bedrijven',
                routeOrFunction: '/importeer-exporteer/importeer-bedrijven',
                position: 10,
                permissions: ['can-import-companies'],
              },
              {
                name: 'Melders',
                routeOrFunction: '/importeer-exporteer/importeer-melders',
                position: 20,
                permissions: ['config-can-import-reporters'],
              },
              {
                name: 'Meldingen',
                routeOrFunction: '/importeer-exporteer/exporteer-meldingen',
                position: 30,
                permissions: ['can-export-reports-into-csv'],
              },
            ]
          },
          {
            name: 'Archivering',
            routeOrFunction: '/archivering',
            icon: 'archive',
            position: 210,
            alwaysOpened: false,
            permissions: [
              'can-manage-archive',
            ],
          },
          /*
           disabled as per MKA-1380
                    {
                      name: 'Archivering',
                      routeOrFunction: '',
                      icon: 'archive',
                      position: 210,
                      alwaysOpened: false,
                      permissions: [
                        'can-manage-archive',
                      ],
                      subItems: [
                        {
                          name: 'Archivering',
                          routeOrFunction: '/archivering/archivering',
                          position: 0,
                          permissions: [
                            'can-manage-archive',
                          ],
                        },
                        {
                          name: 'Anonimiseren',
                          routeOrFunction: '/archivering/anonimiseren',
                          position: 10,
                          permissions: [
                            'can-manage-archive',
                          ]
                        }
                      ]
                    },
          */
          {
            name: 'Sent Emails',
            routeOrFunction: '/sent-emails',
            icon: 'email',
            position: 220,
            alwaysOpened: false,
            permissions: [],
          },
        ]);

        this.sidenavService.addItemsBottom([
          {
            name: 'Help & support',
            routeOrFunction: '/handleiding',
            icon: 'help',
            position: 10
          },

        ]);
      }
    });
  }
}
