<div mat-dialog-title>
  Selectie verwijderen?
</div>
<div mat-dialog-content>
  <p>Weet u zeker dat u deze selectie wilt verwijderen? Dit kan impact hebben op de inrichting van de applicatie.</p>
</div>
<mat-dialog-actions>
  <button [mat-dialog-close]="false" color="accent" mat-raised-button>ANNULEREN</button>
  <button [mat-dialog-close]="true" color="warn" mat-raised-button>BEVESTIGEN</button>
</mat-dialog-actions>
