import { Component } from '@angular/core';
import { ReportPrintService } from '../../services/reports/report-print.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'fury-report-print',
  templateUrl: './report-print.component.html',
  styleUrls: ['./report-print.component.scss'],
})

export class ReportPrintComponent {

  protected readonly environment = environment;

  constructor(public reportPrintService: ReportPrintService) {
  }
}
