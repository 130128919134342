import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { default as screenfull } from 'screenfull';

@Component({
  selector: 'fury-toolbar-fullscreen-toggle',
  templateUrl: './toolbar-fullscreen-toggle.component.html',
  styleUrls: ['./toolbar-fullscreen-toggle.component.scss']
})
export class ToolbarFullscreenToggleComponent implements OnInit {

  isFullscreen: boolean = false;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (typeof screenfull !== 'undefined' && screenfull.isEnabled) {
      screenfull.on('change', () => this.setFullscreen(screenfull.isFullscreen));
    }
  }

  toggleFullscreen(): void {
    if (screenfull.isEnabled) {
      screenfull.toggle().then((): void => {
      });
      this.setFullscreen(screenfull.isFullscreen);
    }
  }

  setFullscreen(isFullscreen: boolean): void {
    this.isFullscreen = isFullscreen;
    this.cd.markForCheck();
  }
}
