import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';
import { ScrollbarModule } from '../../../../@fury/shared/scrollbar/scrollbar.module';
import { EditorComponent } from './editor.component';
import { LoadingOverlayModule } from '../../../../@fury/shared/loading-overlay/loading-overlay.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuryAlertBoxModule } from '../../../../@fury/shared/alert-box/alert-box.module';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { LightboxModule } from 'ng-gallery/lightbox';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { RouterModule } from '@angular/router';
import { QuillModule } from 'ngx-quill';
import { TableFilterDateRangeModule } from '../../../../@fury/shared/table-filters/table-filter-date-range/table-filter-date-range.module';
import { TableFilterSelectModule } from '../../../../@fury/shared/table-filters/table-filter-select/table-filter-select.module';
import { TableFilterStringModule } from '../../../../@fury/shared/table-filters/table-filter-string/table-filter-string.module';
import { ReportHistoryComponent } from './report-history/report-history.component';
import { ReassignReportToOrganisationDialogComponent } from './reassign-report-to-organisation/reassign-report-to-organisation-dialog.component';
import { FindReporterDialogComponent } from './find-reporter-dialog/find-reporter-dialog.component';
import { RemovableCategoryComponent } from './removable-category/removable-category.component';
import { PageHeaderButtonsModule } from '../../../../@fury/shared/page-header-buttons/page-header-buttons.module';
import { ReopenReportDialogComponent } from './reopen-report-dialog/reopen-report-dialog.component';
import { FindCompanyDialogComponent } from './find-company-dialog/find-company-dialog.component';
import { ForwardReportDialogComponent } from './forward-report-dialog/forward-report-dialog.component';
import { AssignReportToDepartmentDialogComponent } from './assign-report-to-department/assign-report-to-department-dialog.component';
import { AssignReportToOrganisationDialogComponent } from './assign-report-to-organisation/assign-report-to-organisation-dialog.component';
import { ResendOpenConfirmationDialogComponent } from './resend-report-confirmation-dialog/resend-open-confirmation-dialog.component';
import { ConfirmLosingChangesDialogComponent } from './confirm-losing-changes-dialog/confirm-losing-changes-dialog.component';
import { TableFiltersModule } from '../../../../@fury/shared/table-filters/table-filters/table-filters.module';
import { MatChipsModule } from '@angular/material/chips';
import { CompanyWithInformatieRegimeDialogComponent } from './company-with-informatie-regime-dialog/company-with-informatie-regime-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ScrollbarModule,
    LoadingOverlayModule,
    ReactiveFormsModule,
    FuryAlertBoxModule,
    MtxDatetimepickerModule,
    LightboxModule,
    LightboxModule,
    MaterialFileInputModule,
    RouterModule,
    QuillModule,
    TableFilterDateRangeModule,
    TableFilterSelectModule,
    TableFilterStringModule,
    PageHeaderButtonsModule,
    FormsModule,
    TableFiltersModule,
    MatChipsModule,
  ],
  declarations: [
    EditorComponent,
    ReportHistoryComponent,
    ReopenReportDialogComponent,
    ReassignReportToOrganisationDialogComponent,
    FindReporterDialogComponent,
    RemovableCategoryComponent,
    FindCompanyDialogComponent,
    ForwardReportDialogComponent,
    AssignReportToDepartmentDialogComponent,
    AssignReportToOrganisationDialogComponent,
    ResendOpenConfirmationDialogComponent,
    ConfirmLosingChangesDialogComponent,
    CompanyWithInformatieRegimeDialogComponent,
  ],
  exports: [
    EditorComponent,
    ReportHistoryComponent,
    ReassignReportToOrganisationDialogComponent,
    FindReporterDialogComponent,
    RemovableCategoryComponent,
    FindCompanyDialogComponent,
    ForwardReportDialogComponent,
    AssignReportToDepartmentDialogComponent,
    AssignReportToOrganisationDialogComponent,
    ResendOpenConfirmationDialogComponent,
    ConfirmLosingChangesDialogComponent,
  ]
})
export class EditorModule {
}
