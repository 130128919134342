<div class="sidenav" fxLayout="column">
  <div [routerLink]="['/']" class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="center center">
    <div>
      <img *ngIf="organisation"
           alt="{{ organisation.name }}"
           class="sidenav-toolbar-logo"
           src="{{ organisation.logo }}"
           id="logo"
      >
      <img *ngIf="!organisation"
           alt="StraatbeeldOnline"
           class="sidenav-toolbar-logo"
           src="/assets/img/logo.png"
           id="logo"
      >
    </div>

    <span fxFlex><!-- fill space --></span>
  </div>

  <fury-scrollbar class="sidenav-items" fxFlex="auto">
    <fury-sidenav-item *ngFor="let item of items$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </fury-scrollbar>
  <div class="sidenav-container" fxLayout="column">
    <div class="app-version">Versie {{ version }}</div>
    <fury-sidenav-item *ngFor="let item of itemsBottom$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </div>
</div>
