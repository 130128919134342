import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../@fury/shared/material-components.module';
import { ScrollbarModule } from '../../../@fury/shared/scrollbar/scrollbar.module';
import { ReportPrintComponent } from './report-print.component';
import { FuryAlertBoxModule } from '../../../@fury/shared/alert-box/alert-box.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ScrollbarModule,
    FuryAlertBoxModule
  ],
  declarations: [ReportPrintComponent],
  exports: [ReportPrintComponent]
})
export class ReportPrintModule {
}
