<div mat-dialog-title>
  Verwijderen uit selectielijst
</div>
<div mat-dialog-content>
  <p>Weet u zeker dat deze melding verwijderd kan worden uit de selectielijst? Motiveer hieronder waarom deze melding verwijderd moet worden.</p>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Motivatie verwijderen selectielijst</mat-label>
      <textarea [formControl]="form.controls.note" matInput maxlength="1000" placeholder="Notitie"></textarea>
    </mat-form-field>
  </form>
</div>
<mat-dialog-actions>
  <button [mat-dialog-close]="false" color="accent" mat-raised-button>ANNULEREN</button>
  <button [disabled]="!form.valid" [mat-dialog-close]="form.value" color="warn" mat-raised-button>BEVESTIGEN</button>
</mat-dialog-actions>
<fury-loading-overlay [isLoading]="isLoading" fill="true"></fury-loading-overlay>
