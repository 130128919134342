import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

// noinspection TsLint
@Component({
  selector: 'fury-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  host: {'class': 'fury-card'},
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuryCard {
}

// noinspection TsLint
@Component({
  selector: 'fury-card-header',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'class': 'fury-card-header'},
  template: `
    <div class="fury-card-header-heading-group">
      <ng-content select="fury-card-header-heading"></ng-content>
      <ng-content select="fury-card-header-subheading"></ng-content>
    </div>
    <ng-content></ng-content>
    <ng-content select="fury-card-header-actions"></ng-content>
  `
})
export class FuryCardHeader {
}

// noinspection TsLint
@Component({
  selector: 'fury-card-content',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'class': 'fury-card-content'},
  template: `
    <ng-content></ng-content>`
})
export class FuryCardContent {
}

// noinspection TsLint
@Directive({
  selector: 'fury-card-header-heading',
  host: {'class': 'fury-card-header-heading'}
})
export class FuryCardHeaderTitle {
}

// noinspection TsLint
@Directive({
  selector: 'fury-card-header-subheading',
  host: {'class': 'fury-card-header-subheading'}
})
export class FuryCardHeaderSubTitle {
}

// noinspection TsLint
@Directive({
  selector: 'fury-card-header-actions',
  host: {'class': 'fury-card-header-actions'}
})
export class FuryCardHeaderActions {
}

// noinspection TsLint
@Component({
  selector: 'fury-card-actions',
  host: {
    'class': 'fury-card-actions',
    '[class.fury-card-actions-align-end]': 'align === "end"',
  },
  template: `
    <ng-container *ngIf="form">
      <div class="padding" fxFlex fxLayout="row" fxLayoutGap="16px">
        <button *ngIf="showCancelButton" type="button" fxFlex color="secondary" mat-raised-button #update>
          <mat-icon>cancel</mat-icon>
          Annuleren
        </button>
        <button fxFlex [disabled]="!form.valid" color="primary" mat-raised-button>
          <mat-icon>file_download</mat-icon>
          {{ successText ?? 'Opslaan' }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!form">
      <ng-content></ng-content>
    </ng-container>`
})
export class FuryCardActions implements AfterViewInit, OnDestroy {
  /** Position of the actions inside the card. */
  @Input() align: 'start' | 'end' = 'start';
  @Input() form: FormGroup = null;
  @Input() successText: string = null;
  @Input() showCancelButton: boolean = true;
  @Input() cancelRedirectUrl: string = null;
  @ViewChild('update', {read: ElementRef}) update: ElementRef;

  private destroy$: Subject<null> = new Subject<null>();

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngAfterViewInit(): void {
    let i: number = 0;
    const interval: NodeJS.Timeout = setInterval((): void => {
      if (typeof this.update !== 'undefined') {
        fromEvent(this.update.nativeElement, 'click').pipe().subscribe((): void => {
          this.router.navigate([(this.cancelRedirectUrl !== null ? this.cancelRedirectUrl : '..')], {relativeTo: this.activatedRoute}).then((): void => {
          });
        });
        clearInterval(interval);
      }
      if (i > 100) {
        clearInterval(interval);
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
