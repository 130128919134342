import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()

export class AddSocketIdInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.api_endpoint) !== -1) {
      let socketId: string = '';

      if (typeof window.Echo !== 'undefined') {
        if (typeof window.Echo.socketId() !== 'undefined') {
          socketId = window.Echo.socketId();
        }
      }

      request = request.clone({
        setHeaders: {
          'X-Socket-ID': socketId
        }
      });
    }

    return next.handle(request);
  }
}
