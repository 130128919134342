import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import each from 'lodash-es/each';
import isArray from 'lodash-es/isArray';
import isEqual from 'lodash-es/isEqual';
import keys from 'lodash-es/keys';
import sortBy from 'lodash-es/sortBy';
import { BehaviorSubject, EMPTY, Subject, Subscription } from 'rxjs';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { catchError, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { MediaObserver } from '@angular/flex-layout';
import { AuthenticationService } from '../../services/base/authentication.service';
import { User } from '../../interfaces/routering/user';
import { ReportService } from '../../services/reports/report.service';
import { MenuReportCounts } from '../../interfaces/menu-report-counts';
import { ReportPanelService } from '../../services/reports/report-panel.service';

@Injectable()
export class SidenavService implements OnDestroy {

  mobileBreakpoint = 'lt-md';

  private user: User = null;

  private reportPermissions: string[] = [
    'can-create-reports',
    'can-update-reports',
    'can-delete-reports',
    'can-print-reports',
    'can-view-reports',
    'can-forward-reports',
    'can-assign-new-reports',
    'can-reject-reports',
    'can-resolve-reports',
    'can-read-reports-from-all-organisations',
  ];

  private _openSubject = new BehaviorSubject<boolean>(this.mediaObserver.isActive(this.mobileBreakpoint));
  open$ = this._openSubject.asObservable();
  private _modeSubject = new BehaviorSubject<'side' | 'over'>(this.mediaObserver.isActive(this.mobileBreakpoint) ? 'over' : 'side');
  mode$ = this._modeSubject.asObservable();
  private _collapsedSubject = new BehaviorSubject<boolean>(false);
  collapsed$ = this._collapsedSubject.asObservable();

  private reportCounts: MenuReportCounts = {
    open: 0,
    closed: 0,
    all: 0
  };
  private shouldRecountReports: boolean = true;

  private sourceUpdatedSubscription: Subscription = null;
  private reportCount$: Subject<null> = new Subject<null>();
  private destroy$: Subject<any> = new Subject<any>();

  constructor(private router: Router,
              private mediaObserver: MediaObserver,
              private authenticationService: AuthenticationService,
              private reportService: ReportService,
              private reportPanelService: ReportPanelService,
  ) {
    this.router.events.pipe(
      filter<NavigationEnd>((event: NavigationEnd): boolean => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe((event: NavigationEnd): void => {
      this.setCurrentlyOpenByRoute(event.url);

      if (this.mediaObserver.isActive(this.mobileBreakpoint)) {
        // Close Sidenav on Mobile after Route Change
        this._openSubject.next(false);
      }
    });

    this.reportPanelService.open$.subscribe((value: boolean): void => {
      if (!value && this.shouldRecountReports) {
        this.shouldRecountReports = false;
        this.recountReports();
      }
    });

    this.mediaObserver.asObservable().pipe(
      map(() => this.mediaObserver.isActive(this.mobileBreakpoint)),
      takeUntil(this.destroy$)
    ).subscribe((isMobile: boolean): void => {
      if (isMobile) {
        this._openSubject.next(false);
        this._modeSubject.next('over');
        this._collapsedSubject.next(false);
      } else {
        this._openSubject.next(true);
        this._modeSubject.next('side');
      }
    });

    this.reportCount$.pipe(
      takeUntil(this.destroy$),
      switchMap(() => {
        return this.authenticationService.user$.pipe(
          takeUntil(this.destroy$),
          take(1),
          switchMap((user: User) => {
            if (user && user.permissions.some((p: string) => this.reportPermissions.includes(p))) {
              return this.reportService.count().pipe(
                takeUntil(this.destroy$),
                map((data: MenuReportCounts): void => {
                  if (data) {
                    this.reportCounts = data;

                    if (this.items.length) {
                      this._items.next(this.items);
                    }
                  }
                })
              );
            }
          }),
          catchError(() => EMPTY)
        );
      }),
      catchError(() => EMPTY)
    ).subscribe();

    this.authenticationService.user$.pipe(takeUntil(this.destroy$)).subscribe((user: User): void => {
      if (user) {
        if (typeof user.permissions !== 'undefined') {
          this.user = user;
        }

        if (typeof user.organisation !== 'undefined') {
          if (this.items.length) {
            this._items.next(this.items);
          }
        }

        this.recountReports();

        if (this.sourceUpdatedSubscription !== null) {
          this.sourceUpdatedSubscription.unsubscribe();
        }
        this.sourceUpdatedSubscription = this.reportService.sourceUpdated.pipe(takeUntil(this.destroy$)).subscribe((): void => {
          if (this.reportPanelService.isOpen) {
            this.shouldRecountReports = true;
          } else {
            this.recountReports();
          }
        });

        let intX: number = 0;
        const int: NodeJS.Timeout = setInterval((): void => {
          if (intX > 100) {
            clearInterval(int);
          }
          if (typeof window.Echo !== 'undefined') {
            clearInterval(int);

            window.Echo.private('reports-count').listen('.updated', (): void => {
              this.recountReports();
            });
          }

          intX++;
        }, 100);
      } else {
        if (this.sourceUpdatedSubscription !== null) {
          this.sourceUpdatedSubscription.unsubscribe();
        }
      }
    });

    this.items$.pipe(takeUntil(this.destroy$)).subscribe((): void => {
      this._items.forEach((items: SidenavItem[]): void => {
        items.forEach((item: SidenavItem): void => {
          if (typeof item.badgeItem !== 'undefined') {
            if (item.badgeItem === 'reports_open' && this.reportCounts.open) {
              item.badge = this.reportCounts.open + '';
              item.badgeColor = '#7cb342';
            }
          }

          if (typeof item.subItems !== 'undefined') {
            item.subItems.forEach((subItem: SidenavItem): void => {
              if (typeof subItem.badgeItem !== 'undefined') {
                if (subItem.badgeItem === 'reports_open' && this.reportCounts.open) {
                  subItem.badge = this.reportCounts.open + '';
                  subItem.badgeColor = '#7cb342';
                }
              }
            });
          }
        });
      });
    });
  }

  /**
   * Sidenav Items
   * @type {BehaviorSubject<SidenavItem[]>}
   * @private
   */
  private _items = new BehaviorSubject<SidenavItem[]>([]);

  items$ = this._items.asObservable();

  get items(): SidenavItem[] {
    return this._items.getValue();
  }

  set items(items: SidenavItem[]) {
    this._items.next(items);
  }

  private _itemsBottom = new BehaviorSubject<SidenavItem[]>([]);

  itemsBottom$ = this._itemsBottom.asObservable();

  get itemsBottom(): SidenavItem[] {
    return this._itemsBottom.getValue();
  }

  set itemsBottom(items: SidenavItem[]) {
    this._itemsBottom.next(items);
  }

  /**
   * Currently Open
   * @type {BehaviorSubject<SidenavItem[]>}
   * @private
   */
  private _currentlyOpen = new BehaviorSubject<SidenavItem[]>([]);

  currentlyOpen$ = this._currentlyOpen.asObservable();

  get currentlyOpen(): SidenavItem[] {
    return this._currentlyOpen.getValue();
  }

  set currentlyOpen(currentlyOpen: SidenavItem[]) {
    this._currentlyOpen.next(currentlyOpen);
  }

  recountReports(): void {
    this.reportCount$.next();
  }

  open(): void {
    this._openSubject.next(true);
  }

  close(): void {
    this._openSubject.next(false);
  }

  setCollapsed(collapsed: boolean): void {
    this._collapsedSubject.next(collapsed);
  }

  toggleCollapsed(): void {
    this._collapsedSubject.next(!this._collapsedSubject.getValue());
  }

  /*
    setExpanded(expanded: boolean): void {
      this._expandedSubject.next(expanded);
    }

    toggleExpanded(): void {
      this._expandedSubject.next(!this._expandedSubject.getValue());
    }
  */

  addItems(items: SidenavItem[]): void {
    items.forEach((item: SidenavItem): void => {
      if (typeof item.permissions === 'undefined' ||
        this.user !== null && this.user.permissions !== null && this.user.permissions.some((p: string) => item.permissions.includes(p)) ||
        this.user !== null && this.user.role === 'super-admin'
      ) {
        let subItems = [];
        if (typeof item.subItems !== 'undefined') {
          subItems = item.subItems.filter((d: SidenavItem) =>
            typeof d.permissions === 'undefined' ||
            this.user !== null && this.user.permissions !== null && this.user.permissions.some((p: string) => d.permissions.includes(p)) ||
            this.user !== null && this.user.role === 'super-admin'
          );
          item.subItems = subItems;
        }

        this.addItem({...item, ...subItems});
      }
    });
  }

  addItemsBottom(items: SidenavItem[]): void {
    items.forEach((item: SidenavItem): void => {
      if (typeof item.permissions === 'undefined' ||
        this.user !== null && this.user.permissions !== null && this.user.permissions.some((p: string) => item.permissions.includes(p)) ||
        this.user !== null && this.user.role === 'super-admin'
      ) {
        let subItems = [];
        if (typeof item.subItems !== 'undefined') {
          subItems = item.subItems.filter((d: SidenavItem) =>
            typeof d.permissions === 'undefined' ||
            this.user !== null && this.user.permissions !== null && this.user.permissions.some((p: string) => d.permissions.includes(p)) ||
            this.user !== null && this.user.role === 'super-admin'
          );
          item.subItems = subItems;
        }

        this.addItemBottom({...item, ...subItems});
      }
    });
  }

  addItem(item: SidenavItem): void {
    const foundIndex: number = this.items.findIndex((existingItem: SidenavItem) => isEqual(existingItem, item));
    if (foundIndex === -1) {
      this.setParentRecursive(item);

      this.items = [...this.items, item];
    }
  }

  addItemBottom(item: SidenavItem): void {
    const foundIndex: number = this.itemsBottom.findIndex((existingItem: SidenavItem) => isEqual(existingItem, item));
    if (foundIndex === -1) {
      this.setParentRecursive(item);

      this.itemsBottom = [...this.itemsBottom, item];
    }
  }

  toggleItemOpen(item: SidenavItem): void {
    let currentlyOpen = this.currentlyOpen;

    if (this.isOpen(item)) {
      if (currentlyOpen.length > 1) {
        currentlyOpen.length = currentlyOpen.indexOf(item);
      } else {
        currentlyOpen = [];
      }
    } else {
      currentlyOpen = this.getParents(item);
    }

    this.currentlyOpen = currentlyOpen;
  }

  sortRecursive(array: SidenavItem[], propertyName: string): SidenavItem[] {
    const that = this;

    array.forEach(function (item: SidenavItem): void {
      const keyArray = keys(item);
      keyArray.forEach(function (key: string): void {
        if (isArray(item[key])) {
          item[key] = that.sortRecursive(item[key], propertyName);
        }
      });
    });

    return sortBy(array, propertyName);
  }

  getItemByRoute(route): SidenavItem {
    return this.getItemByRouteRecursive(route, this.items);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private getParents(item: SidenavItem, items: SidenavItem[] = []) {
    items.unshift(item);

    if (item.parent) {
      return this.getParents(item.parent, items);
    } else {
      return items;
    }
  }

  private isOpen(item: SidenavItem): boolean {
    return (this.currentlyOpen.indexOf(item) > -1);
  }

  private setCurrentlyOpenByRoute(route: string): void {
    const item: SidenavItem = this.getItemByRouteRecursive(route, this.items);
    let currentlyOpen = [];

    if (item && item.parent) {
      currentlyOpen = this.getParents(item);
    } else if (item) {
      currentlyOpen = [item];
    }

    this.currentlyOpen = currentlyOpen;
  }

  private getItemByRouteRecursive(route: string, collection: SidenavItem[]): SidenavItem {
    let result: SidenavItem = collection.find((i: SidenavItem): boolean => i.routeOrFunction === route);

    if (!result) {
      each(collection, (item: SidenavItem): boolean => {
        if (item && item.subItems && item.subItems.length > 0) {
          const found = this.getItemByRouteRecursive(route, item.subItems);

          if (found) {
            result = found;
            return false;
          }
        }
      });
    }

    return result;
  }

  private setParentRecursive(item: SidenavItem): void {
    if (item.subItems && item.subItems.length > 0) {
      item.subItems.forEach((subItem: SidenavItem): void => {
        subItem.parent = item;
        this.setParentRecursive(subItem);
      });
    }
  }
}
