// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { version } from './version';

export const environment = {
  production: true,
  appVersionAddon: '',
  webappName: 'Milieu Klachten Applicatie',
  api_endpoint: 'https://api.milieuklachtencentrale.nl/',
  latitude: 51.55,
  longitude: 5.083333,
  pusher: {
    broadcaster: 'pusher',
    key: '2d39bb78cd8a510d601b',
    cluster: 'eu',
    forceTLS: true,
  },
  sentry: {
    dsn: 'https://d246c19ebab029706266fcaa17c30095@o4507735602298880.ingest.de.sentry.io/4507736944607312',
    version: version
  }
};
