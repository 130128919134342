<div mat-dialog-title>
  Applicatie update beschikbaar
</div>
<div mat-dialog-content>
  <p>Binnen deze applicatie zijn er aanpassingen of updates beschikbaar (functionaliteiten). Wij raden aan om deze update zo snel mogelijk uit te voeren.</p>
</div>
<mat-dialog-actions>
  <button [mat-dialog-close]="false" color="accent" mat-raised-button>Later uitvoeren</button>
  <button [mat-dialog-close]="true" color="primary" mat-raised-button>Start update</button>
</mat-dialog-actions>
