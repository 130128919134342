import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFilterSelectComponent } from './table-filter-select.component';
import { MaterialModule } from '../../material-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  declarations: [TableFilterSelectComponent],
  exports: [TableFilterSelectComponent],
})

export class TableFilterSelectModule {
}
