import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';

@Injectable()

export class AnonymizedService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('archive.anonymized'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ServerResponse>response);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  export(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('archive.anonymized.export'));
  }
}
