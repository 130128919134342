import { MatPaginatorIntl } from '@angular/material/paginator';

export function MatPaginatorIntlDutch(): MatPaginatorIntl {
  const paginatorIntl: MatPaginatorIntl = new MatPaginatorIntl();

  paginatorIntl.firstPageLabel = `Eerste pagina`;
  paginatorIntl.itemsPerPageLabel = 'Regels per pagina:';
  paginatorIntl.lastPageLabel = `Laatste pagina`;

  paginatorIntl.nextPageLabel = 'Volgende pagina';
  paginatorIntl.previousPageLabel = 'Vorige pagina';
  paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `Pagina 0 van 0`;
    }

    // results per page, from - to - total
    const startIndex = page * pageSize,
      endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' of ' + length;
/*
    // pages from - to
    const amountPages = Math.ceil(length / pageSize);
    return `Pagina ${page + 1} van ${amountPages}`;
*/
  };

  return paginatorIntl;
}
