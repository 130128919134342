<div mat-dialog-title>
  Niet-opgeslagen wijzigingen
</div>
<div mat-dialog-content>
  <p>WAARSCHUWING: Er zijn wijzigingen die nog niet zijn opgeslagen. Selecteer ‘Annuleren’ om terug te gaan en deze wijzigingen op te slaan, of ‘Doorgaan’ om de
    pagina af te sluiten zonder de wijzigingen op te slaan.</p>
</div>
<mat-dialog-actions>
  <button [mat-dialog-close]="false" color="accent" mat-raised-button>Annuleren</button>
  <button [mat-dialog-close]="true" color="primary" mat-raised-button>Doorgaan</button>
</mat-dialog-actions>
