import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { KnowledgeBaseArticle } from '../../interfaces/knowledge-base/knowledge-base-article';

@Injectable()

export class KnowledgeBaseArticlesService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(knowledge_base_id: number): Observable<KnowledgeBaseArticle[] | boolean> {
    return new Observable<KnowledgeBaseArticle[] | boolean>((o: Subscriber<KnowledgeBaseArticle[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('configuration.knowledge-base-articles.index', {':knowledge_base_id': knowledge_base_id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<KnowledgeBaseArticle[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(knowledge_base_id: number, id: number): Observable<KnowledgeBaseArticle | boolean> {
    return new Observable<KnowledgeBaseArticle | boolean>((o: Subscriber<KnowledgeBaseArticle | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('configuration.knowledge-base-articles.read', {
        ':knowledge_base_id': knowledge_base_id,
        ':id': id
      }))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<KnowledgeBaseArticle>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(knowledge_base_id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('configuration.knowledge-base-articles.create', {':knowledge_base_id': knowledge_base_id}), formData);
  }

  update(knowledge_base_id: number, id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('configuration.knowledge-base-articles.update', {
      ':knowledge_base_id': knowledge_base_id,
      ':id': id
    }), formData);
  }

  delete(knowledge_base_id: number, id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('configuration.knowledge-base-articles.delete', {
        ':knowledge_base_id': knowledge_base_id,
        ':id': id
      }))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(knowledge_base_id: number, id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('configuration.knowledge-base-articles.restore', {
        ':knowledge_base_id': knowledge_base_id,
        ':id': id
      }))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }
}
